<template>
  <div class="container bg">
    <div v-if="showlist">
      <div class="df jc-sb bg-white head">
        <div class="df">
          <div class="dfc mr50">
            <el-select v-model="minFormdata.videoStatus" placeholder="请选择视频合成状态">
              <el-option v-for="item in $store.state.statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="dfc mr50">
            <el-input v-model="minFormdata.videoName" placeholder="请输入视频名称"></el-input>
          </div>
          <div class="df">
            <el-button plain type="primary" @click="getlive">搜索</el-button>
            <el-button plain @click="clear">清理</el-button>
            <el-button plain type="primary" @click="getlive">刷新</el-button>
            <el-button v-show="!choose" plain type="warning" @click="choose = true">选择下载</el-button>
            <el-button v-show="choose" plain type="warning" @click="choose = false">取消选择</el-button>
            <el-button v-show="choose" plain type="success" @click="download">下载</el-button>
          </div>
        </div>
        <div class="df">
          <el-button type="success" size="small" icon="el-icon-upload" @click="dialogUpload = true">上传视频</el-button>
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showlist = false">创建视频</el-button>
        </div>
      </div>
      <el-checkbox-group v-model="checkList">
        <div class="f-w box pl20">
          <div class="df f-w" v-if="videoList.length > 0">
            <div v-for="item in videoList" :key="item.id" class="imgBox" @click="getItem(item)">
              <img :src="item.video_image" object-fit="cover" alt="" />
              <div class="desc">
                {{ item.title }}

                <div class="time">{{ item.create_time }}</div>
              </div>
              <div v-show="!choose" class="tools" style="color: #ff5733" @click.stop="delitem(item)">
                <i class="el-icon-delete"></i>
              </div>
              <div v-show="choose" class="tools" style="color: #ff5733" @click.stop>
                <el-checkbox v-show="choose" :value="item.id" :label="item.id"></el-checkbox>
              </div>
              <div class="status">
                <div class="sta" :style="item.statusa | getColor">
                  {{ item.statusa | getStatus }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <myempty text="视频"></myempty>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <div v-else>
      <create-video @back="back"></create-video>
    </div>
    <el-dialog :title="activeInfo.title" :visible.sync="dialogVisible" width="28%" :destroy-on-close="true">
      <video ref="v1" :src="activeInfo.url" class="vd" controls></video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="上传视频" :visible.sync="dialogUpload" width="28%" :destroy-on-close="true">
      <div class="df pl15">
        <el-input style="width: 90%" v-model="videoName" placeholder="请输入视频名称"></el-input>
      </div>

      <div class="df mt20">
        <upload-video ref="uploads" :higher="2"></upload-video>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUpload = false">取 消</el-button>
        <el-button type="primary" @click="uploadVideo">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { del_video, get_video_list } from "@/api/live.js";
import { add_div_video } from "@/api/video.js";
import { downloadVideo } from "@/utils/tools";

import myempty from "@/components/myempty.vue";
import createVideo from "./createVideo.vue";
import UploadVideo from "@/components/UploadVideo.vue";

var timer;

export default {
  components: {
    myempty,
    createVideo,
    UploadVideo,
  },
  filters: {
    getStatus: (val) => {
      if (!val) return "";
      return val == "1" ? "合成中" : val == "3" ? "已完成" : "合成失败";
    },
    getColor: (val) => {
      if (!val) return "";
      return val == "1" ? "color:#0052d9;" : val == "3" ? "color:#43CF7C;" : "color:#FF5733;";
    },
  },
  name: "videoList",
  data() {
    return {
      minFormdata: {
        videoStatus: "",
        videoName: "",
      },
      showlist: true,
      choose: false,
      isPlay: false,
      dialogVisible: false,
      dialogUpload: false,
      ipt: "",
      value: "",
      videoName: "",
      activeInfo: {},
      activeI: {},
      downloadList: [],
      videoList: [],
      checkList: [],
    };
  },
  mounted() {
    // 渲染视频
    this.getlive();
    timer = setInterval(() => {
      this.getlive();
    }, 60000);
  },
  destroyed() {
    clearTimeout(timer);
  },
  methods: {
    close() {
      console.log(this.$refs.v1);
      this.dialogVisible = false;
    },
    back() {
      this.showlist = true;
      this.getlive();
    },
    clear() {
      this.minFormdata = {
        videoStatus: "",
        videoName: "",
      };
      this.getlive();
    },
    uploadVideo() {
      if (!this.videoName) {
        this.$message.error("请输入视频名称");
        return;
      }
      if (this.$refs.uploads.fileList.length < 1) {
        this.$message.error("请上传视频后提交克隆");
        return;
      }
      let params = {
        title: this.videoName,
        url: this.$refs.uploads.fileList[0],
      };
      add_div_video(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.dialogUpload = false;
          this.videoName = "";
          this.$refs.uploads.clearFile();
          this.getlive();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getlive() {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      get_video_list({
        page: 1,
        item: 1000,
        type_s: 2,
        statusa: this.minFormdata.videoStatus || "",
        so: this.minFormdata.videoName || "",
      }).then((res) => {
        setTimeout(() => {
          loading.close();
          if (res.code == "200") {
            this.videoList = res.data.list;
          } else {
            this.$message.error(res.msg);
          }
        }, 200);
      });
    },
    download() {
      if (this.checkList.length > 0) {
        this.checkList.map((i) => {
          let obj = this.videoList.find((j) => j.id === i);
          console.log(111, obj);
          if (obj.url) {
            downloadVideo(obj.url);
          }
        });
      } else {
        this.$message.Warning("请选择文件下载！");
      }
    },
    getItem(i) {
      if (i.statusa == "3") {
        this.activeInfo = i;
        this.dialogVisible = true;
      }
    },
    delitem(i) {
      this.activeI = i;
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          del_video({
            video_id: this.activeI.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getlive();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}
/deep/.el-checkbox__inner {
  width: 20px;
  height: 20px;
  border: 1px solid #015ce6 !important;
  margin-left: 3px;
}
/deep/.el-checkbox__inner::after {
  height: 12px;
  left: 7px;
}

/deep/.el-checkbox-group {
  font-size: 15px !important;
}

/deep/.el-main {
  padding: 15px !important;
}

/deep/.el-dialog {
  border-radius: 8px;
}

.tools {
  /deep/.el-checkbox__inner {
    width: 20px;
    height: 20px;
    border: 1px solid #015ce6 !important;
    margin-left: 3px;
  }
  /deep/.el-checkbox__inner::after {
    height: 12px;
    left: 7px;
  }
}

.status {
  position: relative;
  top: -401px;
  left: -3px;
  width: 70px;
  height: 70px;
  opacity: 0.9;
  background: url(~@/assets/img/status.png) no-repeat;
  background-size: contain;
  .sta {
    transform: rotate(315deg);
    font-size: 14px;
    text-align: left;
    padding-top: 5px;
    margin-left: -3px;
    font-weight: 600;
  }
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}
</style>
