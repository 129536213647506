<template>
  <div>
    <div class="con">
      <div class="box left">
        <div class="nav">
          <div class="nitem back" @click="back">
            <img src="~@/assets/img/back.png" alt="" />
          </div>
          <div class="nitem" :class="tabidx == 1 ? 'tabidx' : ''">
            <img src="~@/assets/img/add/list.png" alt="" />
            <div class="mt5">形象</div>
          </div>
        </div>

        <div v-if="figureList.length > 0" style="width: 100%">
          <div v-show="tabidx == 2" style="margin: 15px; text-align: left">
            <el-button type="info" icon="el-icon-picture-outline" size="mini" @click="$refs.imgInput.click()">上传图片</el-button>
          </div>
          <div class="df f-w list">
            <div v-for="(item, idx) in figureList" :key="idx" @click="getItem(item, idx)" :class="index == idx ? 'choose' : ''" class="item">
              <img :src="item.url" object-fit="cover" alt="" />
            </div>
          </div>
        </div>
        <div v-else style="margin: 0 auto">
          <myempty text="形象"></myempty>
        </div>
      </div>

      <div class="box center">
        <!-- <img :src="activeInfo.url" alt="" /> -->
        <img :src="figureList[0].url" alt="" />
      </div>

      <div class="right">
        <div class="top df" style="border-radius: 10px 10px 0 0">
          <div :class="isact == 1 ? 'act' : ''" @click="isact = 1">开启直播</div>
          <div :class="isact == 2 ? 'act' : ''" @click="isact = 2">弹幕列表</div>
        </div>

        <div v-show="isact == 1" class="box1 box2" style="border-radius: 0 0 10px 10px">
          <div class="form">
            <!-- <div><span class="tit">创建时间</span> 2020/02/11 04：12</div> -->
            <div>
              <div class="tit">启用背景图</div>
              <div class="fitem">
                <!-- <el-radio v-model="form.radio" label="1">是</el-radio> -->
                <el-radio v-model="form.radio" label="2">否</el-radio>
              </div>
            </div>
            <div>
              <div class="tit">商品播放顺序</div>
              <div class="fitem">
                <el-radio v-model="option.israndom" :label="false">顺序</el-radio>
                <el-radio v-model="option.israndom" :label="true">随机</el-radio>
              </div>
            </div>

            <div class="tit dfc mt10">
              <el-button type="primary" @click="configuration">获取直播资源</el-button>
              <el-button type="success" @click="openLive">开启直播</el-button>
            </div>

            <!-- <el-button type="primary" @click="teseAudio">测试音频</el-button> -->
            <div v-show="showClose">
              <div class="tit">
                <el-select v-model="form.platform" clearable placeholder="请选择直播平台" @change="selChange">
                  <el-option v-for="i in $store.state.sortList" :label="i.label" :value="i.value" :key="i.value"></el-option>
                </el-select>
              </div>

              <div class="tit" v-show="showAddr">
                <el-input v-model="form.input" placeholder="请输入直播间地址"></el-input>
              </div>
              <div class="tit dfc">
                <el-button type="warning" @click="openWs">获取弹幕</el-button>
                <el-button type="danger" @click="closeLive()">关闭直播</el-button>
              </div>
            </div>
          </div>
        </div>

        <div v-show="isact == 2" class="box1" style="border-radius: 0 0 10px 10px; overflow-y: scroll">
          <div id="listBox" v-if="reviewList.length">
            <div class="litem dfc" v-for="(i, idx) in reviewList" :key="idx">
              <div class="main">{{ i.name }}: {{ i.content }}</div>
            </div>
          </div>
          <div v-else style="margin: 0 auto">
            <myempty text="弹幕"></myempty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import { configuration, check_captions } from "@/api/openlive.js";
import { formatDate } from "@/utils/tools";
import myempty from "@/components/myempty.vue";

export default {
  components: {
    myempty,
  },
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        platform: "",
        radio: "2",
        input: "",
      },
      option: {
        israndom: false,
      },
      value: "",
      savePath: "",
      goods_id: "",
      appName: "",
      tabidx: 1,
      index: 0,
      isact: 1,
      id: null,
      socket1: null,
      type: null,
      showAddr: false,
      showClose: false,
      monitor: false,
      activeInfo: {},
      videoList: [],
      figureList: [],
      imageList: [],
      vlist: [],
      downloadList: [],
      goodsList: [],
      reviewList: [],
      timbreList: [],
      views: {
        type: "vue",
        content: "/#/special/subwindow",
        windowName: "window-vue",
        windowTitle: "vue window",
      },
    };
  },
  mounted() {
    this.init();
    this.figureList.push({ url: this.info.cover_png });
  },
  methods: {
    back() {
      this.$emit("back");
    },
    choose(i) {
      this.tabidx = i;
    },
    selChange(e) {
      console.log("e", e);
      switch (e) {
        case "sph":
          this.showAddr = false;
          this.form.input = "https://channels.weixin.qq.com/platform/live/home";
          break;
        case "qita":
          this.showAddr = false;
          break;
        default:
          this.showAddr = true;
          break;
      }
    },
    getItem(e, idx) {
      this.activeInfo = e;
      this.index = idx;
    },
    handleCurrentChange(e) {
      this.activeAudio = e;
    },
    currentChange(e) {
      this.page = e;
      this.getsound();
    },
    init() {
      // 监听 窗口2 发来的消息
      this.$ipc.removeAllListeners(specialIpcRoute.window2ToWindow1);
      this.$ipc.on(specialIpcRoute.window2ToWindow1, (event, arg) => {
        console.log("监听 窗口2 发来的消息");
        console.log(event, arg);
        this.goods_id = arg.info.goods_id || "";
      });
      this.$ipc.removeAllListeners(ipcApiRoute.ipcSendMsg);
      this.$ipc.on(ipcApiRoute.ipcSendMsg, (event, res) => {
        console.log("res:", res);

        if (res.type == "close") {
          this.disabled = false;
          if (res.content == "点击关闭窗口") {
            // this.closeLive();
            this.act = 1;
            this.form.platform = "";
            this.form.input = "";
            this.showAddr = false;
            this.showClose = false;
            this.$store.commit("saveOpenlive", false);
            this.$ipc.invoke(ipcApiRoute.closeSoftware).then((r) => {});
            this.$ipc.invoke(ipcApiRoute.closeWindow).then((r) => {});
            this.socket1.close();
          }
        } else if (res.type == "downloadDone") {
          this.downloadList.map((i) => {
            if (i.id == res.id) {
              i.isdownload = true;
              console.log("downloadDone:", i);
            }
          });
        }
      });
    },
    configuration() {
      const loading = this.$loading({
        lock: true,
        text: "正在获取直播资源",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      configuration({
        live_id: this.info.id,
      }).then((res) => {
        console.log(111, res);
        if (res.code == "200") {
          this.goodsList = [];
          this.vlist = res.data.video;

          Object.assign(this.option, res.data.times);
          res.data.goods.map((i) => {
            if (i.videvo_ids.length > 0) {
              i.videvo_ids.map((j) => {
                let obj = {
                  id: j,
                  goods_id: i.goods_id,
                };
                this.goodsList.push(obj);
              });
            }
          });
          this.vlist.map((r) => {
            if (r.statusa == 3) {
              let obj = {
                ...r,
                isdownload: false,
              };
              this.downloadList.push(obj);
            }
          });
          this.$ipc.invoke(ipcApiRoute.downloadVideo, this.downloadList).then((r) => {
            console.log("downloadList:", r);
            this.downloadList = [];
            this.downloadList = r.vlist;
            this.savePath = r.path;
          });

          setTimeout(() => {
            loading.close();
          }, 1600);
        } else {
          this.$message.error(res.msg);
          loading.close();
        }
      });
    },
    openLive() {
      if (!this.vlist.length && !this.downloadList.length) {
        this.$message.warning(`未获取直播资源，请点击获取资源后再开播`);
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "直播资源校验中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      if (this.downloadList.length == 0) {
        this.$message.warning(`该直播方案暂无视频请添加后再开启直播！`);
        loading.close();
        return;
      }

      let idx = 0;
      this.downloadList.map((i) => {
        if (i.isdownload == false) idx++;
      });
      loading.close();

      if (idx == 0) {
        this.$ipc.invoke(ipcApiRoute.createWindow, this.views).then((r) => {
          console.log(r);
          this.id = r;
          this.disabled = true;
        });
        const loading1 = this.$loading({
          lock: true,
          text: "正在开启直播",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$store.commit("saveOpenlive", true);

        setTimeout(() => {
          console.log("isran", this.option.israndom);
          loading1.close();
          this.showClose = true;
          this.option.live_id = this.info.id;
          this.sendTosubWindow({
            livelist: this.goodsList,
            path: this.savePath,
            israndom: this.option.israndom,
            option: this.option,
            token: localStorage.getItem("token"),
          });
        }, 4500);
      } else {
        this.$message.warning(`直播资源下载未完成，当前有${idx}个视频下载中！`);
        loading.close();
      }
    },
    openWs() {
      if (!this.form.platform) {
        this.$message.error("请选择直播间类型！");
        return;
      }
      if (this.chooseRoom == "qita") {
        return;
      }
      if (!this.form.input) {
        this.$message.error("请输入直播间地址!");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在开启监听",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.appName = this.form.platform == "mt" || this.form.platform == "pdd" ? "main.exe" : "app.exe";
      this.$ipc.invoke(ipcApiRoute.closeSoftware, "main.exe");
      this.$ipc.invoke(ipcApiRoute.closeSoftware, "app.exe");
      setTimeout(() => {
        let data = {
          path: this.form.platform == "mt" || this.form.platform == "pdd" ? "config.ini" : "setting\\setting.ini",
          addr: this.form.input,
          platform: this.form.platform,
          appName: this.appName,
        };
        this.$ipc.invoke(ipcApiRoute.editLocalIniFile, data).then((r) => {
          loading.close();
          this.$ipc.invoke(ipcApiRoute.openSoftware, this.appName).then((r1) => {
            console.log("resourcePath:", r1);
            if (r1) {
              this.$message.success("正在启动弹幕获取程序，请耐心等待");
              setTimeout(() => {
                this.socket1 = new WebSocket("ws://127.0.0.1:9999/");
                this.isact = 2;
                this.sound_time();
                console.log(this.socket1, 111);
                this.socket1.addEventListener("open", (event) => {
                  console.log("open");
                });

                this.socket1.addEventListener("message", (event) => {
                  console.log("event", event);
                  let res = JSON.parse(event.data);
                  if (res.status == 1 && res.type == "tips" && res.tips.indexOf("获取roomid成功") != -1) {
                    this.roomId = res.room_id;
                    this.showStatus = true;
                    this.$message.success("开始监听直播间");
                  }
                  if (res.status == 0 && res.tips == "未开播") {
                    this.$message.error("检测到该直播间未开播");
                  }

                  console.log("res;", res);
                  if (res.content) {
                    let cotent1 = res.content;
                    console.log("获取到消息", cotent1);
                    let obj = {
                      name: res.name,
                      content: res.content,
                      time: formatDate(new Date()),
                    };
                    this.reviewList.push(obj);
                    console.log("发起请求");
                    check_captions({
                      data: event.data,
                      live_id: this.info.id,
                      goods_id: this.goods_id,
                      type: this.form.platform,
                    }).then((res) => {
                      if (res.code == "200") {
                        if (this.monitor && res.data.type == 2) {
                          //处理助播互动
                          console.log(res.data.value);
                          this.sendTosubWindow({
                            type: "playAudio",
                            audioUrl: res.data.value,
                          });
                          this.sound_time(); //启动间隔引导
                        } else if (res.data.type == 1) {
                          console.log(111);
                          this.sendTosubWindow({
                            type: "insertVideo",
                            id: res.data.value,
                          });
                        }
                      } else {
                        this.$message.error(res.msg);
                      }
                    });
                    document.getElementById("listBox").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                  }
                });
              }, 3000);
            }
          });
        });
      }, 300);
    },
    sound_time() {
      if (this.option.sound_time) {
        this.monitor = false;
        setTimeout(() => {
          this.monitor = true; //发送倒计时结束
        }, this.option.sound_time * 1000);
      }
    },
    getReview() {
      this.isact = this.isact == 1 ? 2 : 1;
    },
    teseAudio() {
      this.sendTosubWindow({
        type: "playAudio",
        audioUrl: "https://yqpeisong.oss-cn-beijing.aliyuncs.com/upload/video/2023-09-13/86a49ae2d9c8a3f35a0b3fd211029db8.mp3",
      });
    },
    closeLive() {
      this.$confirm("确认关闭,确认将关闭直播？")
        .then((_) => {
          this.act = 1;
          this.form.platform = "";
          this.form.input = "";
          this.showAddr = false;
          this.showClose = false;
          this.$store.commit("saveOpenlive", false);
          this.$ipc.invoke(ipcApiRoute.closeSoftware, this.appName);
          this.$ipc.invoke(ipcApiRoute.closeWindow);
          this.socket1.close();
        })
        .catch((_) => {});
    },
    sendTosubWindow(e) {
      if (e) {
        this.$ipc.sendTo(this.id, specialIpcRoute.window1ToWindow2, e);
      } else {
        this.$ipc.sendTo(this.id, specialIpcRoute.window1ToWindow2, {
          msg: "窗口1通过 sendTo 给窗口2发送消息",
          type: "insertVideo",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-select > .el-input {
  width: 127%;
}

::v-deep {
  .el-input__inner {
    border: 0px !important;
  }
  .el-textarea .el-input__count {
    background: #f3f5fd !important;
  }
}
.con {
  height: 93vh;
  background: #d6dff9;
  padding: 16px 13px 10px;
  display: flex;
  justify-content: space-between;

  .box {
    background: #f5f7fe;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
  }

  .left {
    width: 23%;
    display: flex;
    .nav {
      height: 100%;
      border-right: 2px solid #d7e0ed;

      .nitem {
        background: #fefeff;
        border-radius: 10px;
        padding: 12px 6px;
        margin: 20px 7px;
        font-size: 14px;
        width: 60px;

        img {
          width: 30px;
          height: 30px;
        }
      }
      .tabidx {
        border: 2px solid #4f81ff;
      }
    }

    .list {
      padding: 12px;
      overflow: scroll;

      .choose {
        border: 2px solid #4f81ff;
        height: 172px;
      }
    }
  }
  .center {
    margin: 0 14px;
    width: 45%;
    overflow: hidden;
    background: #f4f5fe;

    img {
      width: 493px;
      height: 100%;
    }
  }
  .right {
    width: 25%;

    .box1 {
      border-radius: 10px;
      overflow: hidden;
      color: #547b9c;
      font-size: 15px;
      background: #f4f5fe;
      height: 95%;
    }
    .top {
      justify-content: space-around;
      padding: 10px;
      background-color: #fff;
    }
    .act {
      color: #0362ee;
    }

    .tit {
      padding: 12px;
    }
    .fitem {
      padding: 0px 12px;
    }

    .box2 {
      text-align: left;

      .form {
        padding: 20px 17px;
        /deep/.el-select .el-input__inner:focus,
        /deep/.el-select .el-input__inner {
          border-color: #409eff;
          border: 0 !important;
        }
        /deep/.el-slider__bar {
          background-color: #0362ee !important;
        }
      }
    }

    .litem {
      background: #fff;
      padding: 10px;
      flex-wrap: wrap;
      text-align: left;
      border-bottom: 2px solid #f5f5f5;

      .main {
        width: 80%;
      }
    }
  }
}

.bottom {
  background: #fff;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/.el-button {
    padding: 12px 50px !important;
    background: #347deb !important;
    border-radius: 10px !important;
  }
}

/deep/.el-textarea__inner {
  background: #f3f5fd !important;
  border: 0 !important;
  height: 276px;
}

.item {
  margin-right: 14px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  height: 172px;
  overflow: hidden;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.item img {
  width: 108px;
  height: 172px;
  border-radius: 5px 5px 0 0;
}

.item:last-child,
.item:nth-child(2n) {
  margin-right: 0;
}
</style>
